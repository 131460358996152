
















import { Component, Vue, Prop } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--neutralcolor-low-pure)';

@Component
export default class XCircle extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: '70' }) size!: string;
}
